#navigation-root {
    background-color: var(--color-background);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
    color: var(--color-accent1);
}

#navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    font-size: 1.2em;
}

#lists {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
}

#side-bar {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--color-sidebar);
    display: flex;
    flex-direction: row;
}

#side-bar-icons {
    font-size: 1.5em;
    padding-top: 5px;
}

#side-bar-content {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 5px;
}

#side-bar-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

#side-bar-title {
    font-weight: bold;
    padding: 0 1.4em;
}

#side-bar-pin {
    position: absolute;
    right: 0;
}

#list-navigation {
    display: flex;
    flex-direction: column;
    width: 11em;
    font-size: 0.8em;
    overflow: hidden;
}

#list-navigation-items {
    overflow: auto;
}

#list-settings {
    display: flex;
    flex-direction: column;
    width: 11em;
    font-size: 0.8em;
}

#list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

#list-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    font-size: 1.2em;
}

#sort-menu {
    font-size: 1.2em;
}

#list-groups {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 5em;
}

#user-menu {
    min-width: 10em;
}

.toolbar__group {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
}

.toolbar__group--justify-left {
    justify-content: flex-start;
}

.toolbar__group--justify-right {
    justify-content: flex-end;
}

.link {
    color: inherit;
}

.linkButton {
    color: inherit;
    background-color: var(--color-background);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 5px 20px;
}

.linkButton--border {
    border: 1px solid var(--color-accent1);
}

.linkButton.active {
    background-color: var(--color-accent1);
    color: var(--color-accent2);
}

.toolbar__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 5px;
    font-size: 30px;
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 2px solid var(--color-accent1);
    background-color: var(--color-background);
    box-shadow: 0 0 0.5em;
}

.menu__popup-overlay {
    display: none;
}

.menu__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2em;
    padding: 2px 5px;
}

.menu__item--centered {
    align-items: center;
}

.menu__item--clickable {
    cursor: pointer;
}

.menu__item--hoverable:hover {
    background-color: var(--color-menu-hover);
}

.menu__item--confirm {
    background-color: var(--color-accent1);
    color: var(--color-accent2);
}

.menu__divider {
    height: 1px;
    background-color: var(--color-accent1);
}

.userCard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userCard__avatar {
    font-size: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userCard__fullName {
    font-size: 1.3em;
    font-weight: bold;
}

.userCard__email {
    font-size: 0.7em;
    font-style: italic;
}

.frame {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
}

.form--wide {
    min-width: 250px;
}

.form--medium {
    min-width: 120px;
}

.form--narrow {
    min-width: 100px;
}

.form__field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.2em;
    margin: 0.2em 0;
}

.form__input {
    color: inherit;
    width: 0;
    box-sizing: border-box;
    min-width: 100%;
    height: 2.0em;
    border-style: solid;
    border-color: var(--color-accent1);
    background-color: var(--color-background);
}

.form__button {
    height: 2em;
    background-color: var(--color-accent1);
    border-style: solid;
    border-color: var(--color-accent1);
    color: var(--color-accent2);
    font-size: inherit;
    cursor: pointer;
}

.form__button:hover {
    background-color: var(--color-button-hover);
}

.form__button:disabled {
    background-color: var(--color-disabled);
    border-color: var(--color-disabled-accent);
    color: var(--color-disabled-accent);
    cursor: default;
}

.form__confirmButton {
    color: inherit;
    height: 2em;
    border-style: solid;
    background-color: inherit;
    border-color: var(--color-accent1);
    font-size: inherit;
    cursor: pointer;
}

.form__confirmButton--hover:hover {
    background-color: var(--color-menu-hover);
}

.form__confirmButton--confirm {
    background-color: var(--color-accent1);
    color: var(--color-accent2);
}

.form__confirmButton--confirm:hover {
    background-color: var(--color-button-hover);
}

.alert {
    font-weight: bold;
    min-height: 1.5em;
    width: 250px;
    text-align: center;
}

.alert--error {
    color: var(--color-error);
}

.alert--success {
    color: var(--color-success);
}

.group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5em;
    padding: 0 10px 10px 10px;
    border: 2px solid var(--color-accent1);
    border-radius: 7px;
}

.group--collapsed {
    border-color: var(--color-inactive);
    color: var(--color-inactive);
}

.group__caption {
    text-align: center;
    margin-bottom: 5px;
    padding: 0 10px 0.3em 10px;
    color: inherit;
    cursor: pointer;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 0.5em;
    font-size: 0.8em;
}

.item__handle {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
}

.item__caption {
    cursor: pointer;
    flex-grow: 1;
}

.item__caption--checked {
    text-decoration: line-through;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.2em;
}

.icon--inverted {
    background-color: var(--color-accent1);
    color: var(--color-background);
}

.icon--disabled {
    cursor: default;
    color: var(--color-disabled);
}

.icon--spinning {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.floater {
    position: relative;
}

.floater__content {
    position: absolute;
    width: 100%;
}

.modal-popup-overlay {
    background-color: rgba(0, 0, 0, 0.679);
}

.modal-popup-content {
    background-color: var(--color-background);
    padding: 5px;
    border: 1px solid var(--color-accent1);
    max-width: 80vw;
}