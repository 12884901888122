body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.theme-light {
    --color-background: #fff;
    --color-sidebar: #eee;
    --color-accent1: #f36109;
    --color-accent2: #fff;
    --color-menu-hover: #eee;
    --color-button-hover: #ca5b16;
    --color-disabled: #999;
    --color-disabled-accent: #777;
    --color-error: #e63316;
    --color-success: #ff9639;
    --color-inactive: #aaa;
}

.theme-dark {
    --color-background: #131311;
    --color-sidebar: #222;
    --color-accent1: #f36109;
    --color-accent2: #fff;
    --color-menu-hover: #222;
    --color-button-hover: #d87232;
    --color-disabled: #2a2a2a;
    --color-disabled-accent: #777;
    --color-error: #e63316;
    --color-success: #ff9639;
    --color-inactive: #aaa;
}